<template>
  <div>
    <el-form
      :rules="rules"
      ref="form"
      :model="form"
      label-width="200px"
    >
      <el-form-item label="微信支付：">
        <el-switch
          :active-value="1"
          :inactive-value="0"
          v-model.number="form.isWx"
        ></el-switch>
      </el-form-item>
      <el-form-item label="支付类型：">
        <el-select
          v-model.number="form.wxMode"
          placeholder="请选择活动区域"
        >
          <el-option
            label="请选择支付类型"
            :value="0"
          ></el-option>
          <el-option
            label="境内普通商户"
            :value="1"
          ></el-option>
          <el-option
            label="境内服务商"
            :value="2"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="form.wxMode===2"
        label="服务商公众号(AppId)："
        prop="wxSubAppId"
      >
        <el-input
          style="width:225px;"
          v-model="form.wxAppId"
        ></el-input>
      </el-form-item>
      <el-form-item
        v-if="form.wxMode===2"
        label="服务商支付商户号(Mch_Id)："
        prop="wxSubMchId"
      >
        <el-input
          style="width:225px;"
          v-model="form.wxMchId"
        ></el-input>
      </el-form-item>

      <el-form-item
        label="支付Id(AppId)："
        prop="wxAppId"
      >
        <el-input
          v-if ="form.wxMode === 1"
          style="width:225px;"
          v-model="form.wxAppId"
        ></el-input>
        <el-input
          v-else
          style="width:225px;"
          v-model="form.wxSubAppId"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="支付商户号(Mch_Id)："
        prop="wxMchId"
      >
        <el-input
          v-if ="form.wxMode === 1"
          style="width:225px;"
          v-model="form.wxMchId"
        ></el-input>
        <el-input
          v-else
          style="width:225px;"
          v-model="form.wxSubMchId"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="支付密钥(APIKEY)："
        prop="wxKey"
      >
        <el-input
          style="width:225px;"
          v-model="form.wxKey"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="CERT证书文件："
        prop="wxCertPem"
      >
        <div style="position:relative;">
          <el-button
            class="button-file"
            type="primary"
            @click="handleTriggerClick"
          >{{form.wxKeyPem===''?'未选择文件':'已选择文件'}}</el-button>
          <input
            class="input-file"
            type="file"
            ref="picers"
            accept=".pem"
            @change="uploadFile"
          />
        </div>

      </el-form-item>
      <el-form-item
        label="KEY密钥文件："
        prop="wxKeyPem"
      >
        <div style="position:relative;">
          <el-button
            class="button-file"
            type="primary"
            @click="handleTriggerClick2"
          >{{form.wxKeyPem===''?'未选择文件':'已选择文件'}}</el-button>
          <input
            class="input-file"
            type="file"
            ref="picers2"
            accept=".pem "
            @change="uploadFile2"
          />
        </div>
      </el-form-item>
    </el-form>
    <div class="btn-wrap">
      <el-button
        type="primary"
        @click="handleSave"
      >保存</el-button>
    </div>
  </div>
</template>

<script>
import { settingPayment, settingPaymentUpdate } from '@/api/marketing/shop/set'
export default {
  data () {
    return {
      wxCertPem: '',
      rules: {
        wxCertPem: [
          { required: true, message: '请选择上传文件', validator: this._validWxCertPem }
        ],
        wxKeyPem: [
          { required: true, message: '请选择上传文件', validator: this._validWxCertPem }
        ],
        wxSubAppId: [
          { required: true, message: '请填写', validator: this._validWxSubAppId }
        ],
        wxSubMchId: [
          { required: true, message: '请填写', validator: this._validWxSubAppId }
        ],
        wxAppId: [
          { required: true, message: '请填写', trigger: 'blur' }
        ],
        wxMchId: [
          { required: true, message: '请填写', trigger: 'blur' }
        ],
        wxKey: [
          { required: true, message: '请填写', trigger: 'blur' }
        ]
      },
      form: {
        isWx: 0,
        wxMode: 1,
        wxAppId: '',
        wxMchId: '',
        wxKey: '',
        wxSubMchId: '',
        wxSubAppId: '',
        wxCertPem: '',
        wxKeyPem: ''
      }
    }
  },
  methods: {
    init () {
      settingPayment().then((res) => {
        this.form = res.data
      })
    },
    handleSave () {
      this.$refs.form.validate((valid) => {
        if (!valid && this.form.isWx === 1) {
          return
        }
        var form1 = new FormData()
        for (let key in this.form) {
          form1.append(key, this.form[key])
        }
        settingPaymentUpdate(form1).then((res) => {
          if (res.code === 0) {
            this.$message({ message: '保存成功', type: 'success' })
          }
        })
      })
    },
    _validWxSubAppId (rule, value, callback) {
      let flag = this.form.isWx === 1
      if (!flag) {
        callback(new Error('请填写'))
      }
      callback()
    },
    _validWxCertPem (rule, value, callback) {
      let flag = this.form.wxCertPem === '' || this.form.wxKeyPem === ''
      if (flag) {
        callback(new Error('请选择上传文件'))
      }
      callback()
    },
    handleTriggerClick () {
      setTimeout(() => {
        this.$refs.picers.click()
      })
    },
    handleTriggerClick2 () {
      setTimeout(() => {
        this.$refs.picers2.click()
      })
    },
    uploadFile () {
      const selectedFile = this.$refs.picers.files[0]
      this.form.wxCertPem = selectedFile
    },
    uploadFile2 () {
      const selectedFile = this.$refs.picers2.files[0]
      this.form.wxKeyPem = selectedFile
    }
  },
  mounted () {
    this.init()
  }
}
</script>
<style lang="scss" scoped>
.avatar {
  width: 70px;
  height: 70px;
  img {
    width: 100%;
    height: 100%;
  }
}
.input-file {
  display: none;
  z-index: -11111;
  width: 0px;
  height: 1px;
}
.button-file {
  width: 225px;
  position: absolute;
  background: #fff;
  z-index: 2;
  color: $-theme-color;
}
</style>
